import styled from "@emotion/styled";
import React from "react";
import DownArrowIcon from "@/components/icons/DownArrowIcon";
import SpeakerIcon from "@/components/icons/SpeakerIcon";
import PaperPlaneIcon from "../icons/PaperPlaneIcon";
import hoverColor from "@/utils/hoverColor";
import { Box, useResponsive } from "@oskarengstrom/sage-ui";

const transition = "all 0.05s linear";

export default function MenuContactButton({
  color = "white",
  size = 3.25,
  iconSize = 24,
  ...restProps
}) {
  const { breakpoint } = useResponsive();

  return (
    <Wrapper
      color={color}
      height={size || ["2.5rem", "4.5rem"]}
      width={size || ["2.5rem", "4.5rem"]}
      {...restProps}
    >
      {breakpoint === "sm" ? (
        <PaperPlaneIcon size={20} />
      ) : (
        <PaperPlaneIcon size={iconSize || 32} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-radius: 100%;
  background-color: ${(props) =>
    props.color || props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.constants.colors.black};

  &:hover {
    /* background-color: ${(props) =>
      hoverColor(props.color || props.theme.palette.primary.main)}; */
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  transition: ${transition};
`;
