import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Stack,
  Typography as T,
  Box,
  useResponsive,
} from "@oskarengstrom/sage-ui";
import styled from "@emotion/styled";
import ToggleButton from "../ui/ToggleButton";
import { linkResolver } from "prismic-configuration";
import { NavContext } from "./Nav";
import gsap from "gsap";
import { useTransition } from "@/hooks/Transition";
import { baseTheme } from "@/styles/theme";
import slugifyString from "@/utils/slugifyString";
import { useSetRecoilState } from "recoil";
import { pointerState } from "@/contexts/pointer/pointerState";

export default function MenuItemGroup({ item }) {
  const { activeCategory, setActiveCategory } = useContext(NavContext);
  const { TransitionLink } = useTransition();
  const [hover, setHover] = useState(false);
  const { isMobile } = useResponsive();
  const setPointer = useSetRecoilState(pointerState);

  useEffect(() => {
    gsap.set(`.menu-item-sub-${slugifyString(item.primary.primary_label)}`, {
      height: 0,
    });
  }, []);

  useEffect(() => {
    if (activeCategory === slugifyString(item.primary.primary_label)) {
      gsap.to(`.menu-item-sub-${slugifyString(item.primary.primary_label)}`, {
        height: "auto",
        autoAlpha: 1,
        duration: 0.25,
        ease: "power4.out",
      });
    } else {
      gsap.to(`.menu-item-sub-${slugifyString(item.primary.primary_label)}`, {
        height: 0,
        autoAlpha: 0,
        duration: 0.25,
        ease: "power4.out",
      });
    }
  }, [activeCategory]);

  const handleClick = () => {
    if (activeCategory === slugifyString(item.primary.primary_label)) {
      setActiveCategory(null);
    } else {
      setActiveCategory(slugifyString(item.primary.primary_label));
    }
  };

  return (
    <Stack className="menu-item" alignItems="center" gap={0.5}>
      <ToggleButton
        label={item.primary.primary_label}
        color={baseTheme.constants.colors[item.primary.theme.slug].tertiary}
        toggle={activeCategory === slugifyString(item.primary.primary_label)}
        onClick={handleClick}
        // onTouchStart={handleClick}
        uid={slugifyString(item.primary.primary_label)}
        tabIndex={0}
        hover={
          activeCategory === slugifyString(item.primary.primary_label) || hover
        }
        onMouseEnter={() => {
          if (!isMobile) {
            setHover(true);
          }
          setPointer({ type: "default" });
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setHover(false);
          }
          setPointer(false);
        }}
        onFocus={() => {
          if (
            !isMobile &&
            activeCategory !== slugifyString(item.primary.primary_label)
          ) {
            setTimeout(() => {
              // bugfix for closing groups
              setActiveCategory(slugifyString(item.primary.primary_label));
            }, 100);
          }
        }}
      />
      <Stack
        className={`menu-item-sub-${slugifyString(item.primary.primary_label)}`}
      >
        <Stack gap={[0, null, 0.75]} py={1}>
          {item.items.map((article) => (
            <TransitionLink
              key={article.label}
              href={linkResolver(article.link)}
              color={
                baseTheme.constants.colors[item.primary.theme.slug].tertiary
              }
              onMouseEnter={() => setPointer({ type: "default" })}
              onMouseLeave={() => setPointer(false)}
            >
              <Stack
                height={"44px"}
                px={1.5}
                justifyContent="center"
                alignItems="center"
                css={(theme) => ({
                  flexShrink: 0,
                  "&:hover .article-nav-item": {
                    color: theme.constants.colors.alpha.grey,
                  },
                  "&:focus": {
                    outline: "none",
                    "& .article-nav-item": {
                      color: theme.constants.colors.alpha.grey,
                    },
                  },
                })}
                tabIndex={0}
              >
                <T className="article-nav-item" variant="labelLarge">
                  {article.label}
                </T>
              </Stack>
            </TransitionLink>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
