import styled from "@emotion/styled";
import { Typography, mq, Box } from "@oskarengstrom/sage-ui";
import React from "react";
import CloseIcon from "../icons/CloseIcon";
import ListIcon from "../icons/ListIcon";
import hoverColor from "@/utils/hoverColor";

export default function MenuButton({ open = false, ...restProps }) {
  return (
    <>
      <MobileWrapper
        open={open}
        // display={["flex", null, "none"]}
        css={{
          display: "none",
          [mq.only.touchDevice]: {
            display: "flex",
          },
        }}
        {...restProps}
      >
        {open ? (
          <CloseIcon size={20} color="white" />
        ) : (
          <ListIcon size={20} color="black" />
        )}
      </MobileWrapper>
      <Wrapper
        open={open}
        // display={["none", null, "flex"]}
        css={{
          display: "flex",
          [mq.only.touchDevice]: {
            display: "none",
          },
        }}
        {...restProps}
      >
        {open ? <CloseIcon /> : <ListIcon />}

        <Typography
          fontSize="16px"
          fontFamily="var(--ica-rubrik)"
          color="currentColor"
          userSelect="none"
        >
          Meny
        </Typography>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Box)`
  height: 3.25rem;
  border-radius: 1.75rem;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  padding: 1.25rem;

  /* ${mq.up.lr} { */
  box-shadow: ${({ theme }) => theme.constants.shadows.button};
  /* } */

  transition: all 120ms linear;

  ${({ open, theme }) =>
    !open &&
    `
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background};
    &:hover {
      color: ${theme.palette.text.secondary};
    }
  `}
  ${({ open, theme }) =>
    open &&
    `
    color: ${theme.constants.colors.white};
    background-color: ${theme.constants.colors.black};
    &:hover {
      color: ${theme.palette.text.secondary};
    }
  `};
`;

const MobileWrapper = styled(Wrapper)`
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
`;
