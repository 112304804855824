export function blobX(boxX) {
  return (x) => {
    const boxXNumber = Number(boxX.replace("px", ""));
    let xNumber = 0;
    if (x.includes("rem")) {
      xNumber = Number(x.replace("rem", "")) * 16;
    } else if (x.includes("px")) {
      xNumber = Number(x.replace("px", ""));
    }
    return boxXNumber + xNumber + "px";
  };
}

export function blobY(boxY) {
  return (y) => {
    const boxYNumber = Number(boxY.replace("px", ""));
    let yNumber = 0;
    if (y.includes("rem")) {
      yNumber = Number(y.replace("rem", "")) * 16;
    } else if (y.includes("px")) {
      yNumber = Number(y.replace("px", ""));
    }
    return boxYNumber + yNumber + "px";
  };
}
