import { useResponsive } from "@oskarengstrom/sage-ui";
import { useEffect, useRef, useState } from "react";

// Usage
// function App() {
//     const [hoverRef, isHovered] = useHover();
//     return <div ref={hoverRef}>{isHovered ? "😁" : "☹️"}</div>;
//   }

export function useHover() {
  const { isMobile } = useResponsive();
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node && isMobile) {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
        return;
      }
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current, isMobile] // Recall only if ref changes
  );
  return [ref, value];
}
