import { useRef } from "react";
import {
  SageProvider,
  useSSRLayoutEffect,
  KeepAspectRatio,
  mq,
} from "@oskarengstrom/sage-ui";
import { baseTheme, themes } from "@/styles/theme";
import styled from "@emotion/styled";
import { PrismicNextImage } from "@prismicio/next";
import PlayIcon from "@/components/icons/PlayIcon";
import { gsap } from "gsap";
import getProps from "./getProps";
import RichText from "@/utils/RichText";
import { pointerState } from "@/contexts/pointer/pointerState";
import { useTheme, ThemeContext } from "@emotion/react";
import useVideoModal from "@/utils/useVideoModal";
import PortalCloseButton from "@/components/ui/PortalCloseButton";
import { useSetRecoilState } from "recoil";
import { css } from "@emotion/react";

const CanvasVideo = ({ slice }) => {
  const ref = useRef(null);
  const tl = useRef(gsap.timeline({ paused: true }));
  const theme_ = useTheme(ThemeContext);
  const setPointer = useSetRecoilState(pointerState);
  const { Modal, setOpen } = useVideoModal();

  const { section_id, title, description, video_embed_url, theme, thumbnail } =
    slice.primary;

  const props = getProps(section_id);
  let styles = {};

  if (typeof props.generateStyles === "function") {
    styles = props.generateStyles({ theme: theme_ });
  }

  useSSRLayoutEffect(() => {
    if (ref.current && tl.current) {
      const ctx = gsap.context(() => {
        tl.current.to(`#video-thumbnail_${section_id}`, {
          "--c": "33%",
          duration: 0.4,
          ease: "power3",
          y: -40,
        });
        tl.current.to(
          `#video-thumbnail-outer_${section_id}`,
          {
            "--c2": "35%",
            duration: 0.4,
            ease: "power3",
            y: -40,
          },
          0
        );

        tl.current.to(
          `#icon_${section_id}`,
          {
            y: -55,
            duration: 0.4,
            ease: "power3",
          },
          0
        );

        tl.current.to(
          `#video-thumbnail-outer_${section_id}`,
          {
            backgroundColor: "var(--darker)",
            duration: 0.4,
            ease: "linear",
          },
          0
        );
        tl.current.to(
          "h3",
          {
            "--scale": 0.9,
            y: -20,
            duration: 0.4,
            ease: "power3",
          },
          0
        );
        tl.current.to(
          ".canvas-video-text > p",
          {
            opacity: 1,
            y: -40,
            duration: 0.4,
            ease: "power3",
          },
          "-=0.4"
        );
        tl.current.to(
          ".canvas-video-text",
          {
            duration: 0.4,
            ease: "power3",
          },
          0
        );
      }, ref);

      return () => {
        ctx.revert();
      };
    }
  }, [ref.current, tl.current]);

  return (
    <SageProvider theme={themes[theme?.slug] || baseTheme}>
      <Container
        ref={ref}
        config={styles}
        onMouseEnter={() => {
          setPointer(false);
          tl.current?.play();
        }}
        onMouseLeave={() => {
          tl.current?.reverse();
        }}
        onClick={(e) => {
          e.stopPropagation();
          tl.current?.reverse();
          setOpen(true);
        }}
      >
        <div id={section_id}>
          <OuterCircle id={`video-thumbnail-outer_${section_id}`} />
          <Image
            id={`video-thumbnail_${section_id}`}
            field={thumbnail}
            fill={true}
            sizes="400px"
          />
          <Play id={`icon_${section_id}`} />

          <TextBlock className="canvas-video-text">
            <RichText
              field={title}
              variant="heading3"
              as="h3"
              css={css`
                --scale: 0.65;
                transform: scale(var(--scale));
                transform-origin: 50% 50%;
                text-align: center;
              `}
            />
            <RichText
              variant="paragraphMedium"
              field={description}
              css={css`
                margin-top: 1rem;
                max-width: 10rem;
                text-align: center;
                opacity: 0;
                ${mq.only.touchDevice} {
                  opacity: 1;
                  margin-top: 0;
                  max-width: 100%;
                }
              `}
            />
          </TextBlock>
        </div>
      </Container>
      <Modal>
        <PortalCloseButton
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        />
        <KeepAspectRatio ratio={16 / 9}>
          <iframe
            width="100%"
            height="100%"
            src={video_embed_url}
            css={{
              height: "100%",
              width: "100%",
              borderRadius: "1rem",
              overflow: "hidden",
              position: "relative",
              zIndex: 1,
            }}
          ></iframe>
        </KeepAspectRatio>
      </Modal>
    </SageProvider>
  );
};

const Container = styled.div`
  ${(props) => ({ ...(props.config || {}) })};
  ${mq.only.touchDevice} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

const TextBlock = styled.div`
  position: absolute;
  left: 0;
  top: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq.only.touchDevice} {
    position: relative;
  }
`;

const Image = styled(PrismicNextImage)`
  --c: 20%;
  background: tranparent;
  object-fit: cover;
  object-position: center;
  clip-path: circle(var(--c) at 50% 50%);
`;

const OuterCircle = styled.div`
  --c2: 25%;
  --lighter: ${({ theme }) => theme.palette.primary.lighter};
  --darker: ${({ theme }) => theme.palette.primary.main};
  background-color: var(--lighter);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: circle(var(--c2) at 50% 50%);
`;

const Play = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.palette.background};
  pointer-events: none;
`;

export default CanvasVideo;
