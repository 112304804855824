import { mq } from "@oskarengstrom/sage-ui";
function generateStyles(scope) {
  const { containerWidth, containerHeight, theme } = scope;

  return {
    "#canvas-video2": {
      "--size": "15rem",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(46rem, 31rem)",
      width: "var(--size)",
      height: "var(--size)",
      [mq.only.touchDevice]: {
        position: "relative",
        top: "auto",
        left: "auto",
        transform: "none",
      },
    },
  };
}

export default {
  generateStyles,
};
