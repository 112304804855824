import hjarnanVideo from "./configurations/hjarnan-video";
import teknikVideo from "./configurations/teknik-video";
import framtidsscenarierVideo from "./configurations/framtidsscenarier-video";
import canvasVideo1 from "./configurations/canvas-video1";
import canvasVideo2 from "./configurations/canvas-video2";
import canvasVideo3 from "./configurations/canvas-video3";

export default function getProps(section_id) {
  switch (section_id) {
    case "hjarnan-video":
      return hjarnanVideo;
    case "teknik-video":
      return teknikVideo;
    case "framtidsscenarier-video":
      return framtidsscenarierVideo;
    case "canvas-video1":
      return canvasVideo1;
    case "canvas-video2":
      return canvasVideo2;
    case "canvas-video3":
      return canvasVideo3;
    default:
      return {};
  }
}
