export default function slugifyString(string) {
  if (typeof string !== "string") {
    console.warn("slugifyString(): Not a string:", string);
    return "";
  }
  // Remove all non-alphanumeric characters using a regular expression
  string = string.replace(/[^\w\d\s]/gi, "");

  // Convert to lowercase
  string = string.toLowerCase();

  // Replace all spaces with dashes
  string = string.replace(/\s+/g, "-");

  // Remove any remaining non-alphanumeric characters
  string = string.replace(/[^\w\d-]/g, "");

  return string;
}
