import React, { useEffect, useRef, useState } from "react";
import NavBar from "./NavBar";
import {
  useSSRLayoutEffect,
  Typography as T,
  useResponsive,
} from "@oskarengstrom/sage-ui";
import gsap from "gsap";
import OpenMenu from "./OpenMenu";
import { useRouter } from "next/router";
import scrollLock from "scroll-lock";
import useDirectionScroll from "@/hooks/useDirectionScroll";
import { useKeyDown } from "@/hooks/useKeyDown";

export const NavContext = React.createContext();

export default function Nav({ navData, backButton = false }) {
  const [open, setOpen] = useState(false);
  const tl = useRef(
    gsap.timeline({
      paused: true,
      onReverseComplete: () => setActiveCategory(null),
    })
  );
  const [activeCategory, setActiveCategory] = useState(null);
  const router = useRouter();
  const { toggle: hideMenuByScroll } = useDirectionScroll({ threshold: 400 });
  const { breakpointIndex } = useResponsive();

  useKeyDown(() => {
    setOpen(false);
  }, ["Escape", "m"]);

  useKeyDown(() => {
    setOpen(true);
  }, ["m"]);

  useEffect(() => {
    open
      ? tl.current.timeScale(1).play()
      : tl.current.progress() > 0 && tl.current.timeScale(1.5).reverse();
  }, [open]);

  useEffect(() => {
    const scrollableElement = document.querySelector("#menu");
    open
      ? scrollLock.disablePageScroll(scrollableElement)
      : scrollLock.enablePageScroll(scrollableElement);
  }, [open]);

  const onRouteChangeStart = () => {
    tl.current.progress(0);
    tl.current.pause();
    setOpen(false);
  };

  // useLogEffect(open);

  useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeStart);
    router.events.on("routeChangeError", onRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeStart);
      router.events.off("routeChangeError", onRouteChangeStart);
    };
  }, []);

  useSSRLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current.fromTo(
        ".article-nav-button",
        {
          autoAlpha: 1,
          y: 0,
        },
        {
          autoAlpha: 0,
          y: 5,
          duration: 0.2,
          ease: "power1.out",
        },
        0
      );

      tl.current.from(
        "#menu-bg",
        {
          autoAlpha: 0,
          duration: 0.3,
          ease: "power2.out",
        },
        0
      );

      tl.current.from(
        "#menu",
        {
          autoAlpha: 0,
          duration: 0.1,
          ease: "power2.out",
        },
        0
      );
      tl.current.from(
        "#menu",
        {
          scale: 1.2,
          duration: 0.6,
          ease: "power2.out",
        },
        0
      );

      tl.current.from(
        [
          ".article-nav-item-welcome",
          ".menu-item",
          ".article-nav-item-wrapper > *",
        ],
        {
          y: 10,
          autoAlpha: 0,
          duration: 0.5,
          ease: "power3.out",
          stagger: 0.05,
        },
        0
      );
    });

    return () => ctx.revert();
  }, [breakpointIndex]);

  useEffect(() => {
    if (hideMenuByScroll) {
      gsap.to("#nav-bar", {
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: "power4.out",
      });
    } else {
      gsap.to("#nav-bar", {
        y: -50,
        opacity: 0,
        duration: 0.5,
        ease: "power4.in",
      });
    }
  }, [hideMenuByScroll]);

  return (
    <NavContext.Provider
      value={{
        open,
        setOpen,
        backButton,
        navData,
        activeCategory,
        setActiveCategory,
      }}
    >
      <NavBar />
      <OpenMenu />
    </NavContext.Provider>
  );
}
