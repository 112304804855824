import PortalCloseButton from "@/components/ui/PortalCloseButton";
import { useKeyDown } from "@/hooks/useKeyDown";
import { Global, css } from "@emotion/react";
import { Box, Stack, useSSRLayoutEffect } from "@oskarengstrom/sage-ui";
import React, { useEffect, useRef, useState } from "react";
import usePortal from "react-cool-portal";
import scrollLock from "scroll-lock";
// import gsap from "gsap";

export default function useVideoModal() {
  const [open, setOpenState] = useState(false);
  const { Portal, isShow, show, hide, toggle } = usePortal({
    defaultShow: false,
    containerId: "video-portal",
  });

  useEffect(() => {
    open ? show() : hide();
  }, [open]);

  useEffect(() => {
    const pointer = document.getElementById("custom_pointer");
    if (pointer) {
      pointer.style.opacity = open ? 0 : 1;
    }
  }, [open]);

  const setOpen = (bool) => {
    if (bool) {
      scrollLock.disablePageScroll();
      setOpenState(bool);
    } else {
      scrollLock.enablePageScroll();
      setOpenState(bool);
    }
  };

  const Modal = ({ children }) => {
    useKeyDown(() => {
      setOpen(false);
    }, ["Escape"]);

    return (
      <Portal>
        <Global
          styles={css`
            #video-portal {
              z-index: 999999;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: initial;
            }
          `}
        />
        <Stack
          position="fixed"
          inset="0"
          backgroundColor="rgba(0, 0, 0, 0.9)"
          alignItems="center"
          justifyContent="center"
          p={[1, 2, 4, 8]}
          onClick={(e) => setOpen(false)}
        >
          <PortalCloseButton
            onClick={() => {
              scrollLock.enablePageScroll();
              setOpen(false);
            }}
          />
          {children}
        </Stack>
      </Portal>
    );
  };

  return { Modal, open, setOpen };
}
