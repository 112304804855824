import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import styled from "@emotion/styled";
import { OrbitControls } from "@react-three/drei";

const Points = ({ geometryData, textureData }) => {
  const ref = useRef();
  const groupRef = useRef();
  const materialRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.3;
    if (materialRef.current) {
      //materialRef.current.uniforms.time.value += delta;
    }
  });

  const geometry = useMemo(() => {
    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(geometryData.positions, 3)
    );

    bufferGeometry.setAttribute(
      "uv",
      new THREE.Float32BufferAttribute(geometryData.uvs, 2)
    );

    bufferGeometry.center();

    return bufferGeometry;
  }, [geometryData]);

  const texture = useMemo(() => {
    const dataTexture = new THREE.DataTexture(
      textureData.pixels,
      textureData.width,
      textureData.height,
      THREE.RGBAFormat
    );
    dataTexture.needsUpdate = true;
    return dataTexture;
  }, [textureData]);

  return (
    <group ref={groupRef} position={[0, 0, 0]}>
      <points geometry={geometry} ref={ref} position={[0, 0, 2]}>
        <shaderMaterial
          ref={materialRef}
          vertexColors={true}
          sizeAttenuation={true}
          alphaText={0.5}
          uniforms={{
            imageTexture: { value: texture },
          }}
          vertexShader={`
          varying float brightness; 
          varying vec2 vUv; 

          uniform sampler2D imageTexture;  

          void main() {
            vUv = uv; 
            vec4 texColor = texture2D(imageTexture, vUv);  

            float r = texColor.r; 
            float g = texColor.g; 
            float b = texColor.b; 

            brightness = (r + g + b) / 3.0; 


            vec3 newPos = vec3(position.x, position.y, brightness * -4.0 );  
            vec4 mvPosition = modelViewMatrix * vec4(newPos, 1.0);
            gl_Position = projectionMatrix * mvPosition;
            gl_PointSize = 0.2 + 3.0 * (1.0 - brightness);  
          }
        `}
          fragmentShader={`
          void main() {
            gl_FragColor = vec4(0.0,0.0,0.0,1.0); 
          }
        `}
          onUpdate={(material) => {
            material.uniforms.imageTexture.value = texture;
            material.needsUpdate = true;
          }}
        />
      </points>
    </group>
  );
};

const MotionGraphic = ({ field, config, id, className }) => {
  const [isReady, setIsReady] = useState(false);
  const [geometryData, setGeometryData] = useState(null);
  const [textureData, setTextureData] = useState(null);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(field.url, (texture) => {
      const canvas = document.createElement("canvas");
      const w = 200;
      const h = 200;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(texture.image, 0, 0, w, h);

      const imageData = ctx.getImageData(0, 0, w, h);
      const pixels = imageData.data;
      const tiles = w;
      const tileSize = w / tiles;

      const positions = [];
      const uvs = [];

      let sumX = 0;
      let sumY = 0;
      let count = 0;

      for (let x = 0; x < tiles; x++) {
        for (let y = 0; y < tiles; y++) {
          const i = (y * tileSize * w + x * tileSize) * 4;
          const r = pixels[i] / 255;
          const g = pixels[i + 1] / 255;
          const b = pixels[i + 2] / 255;

          const whiteThreshold = 0.9;
          const blackThreshold = 0.05;

          // Skip white pixels && transparent pixels
          if (
            (r > whiteThreshold && g > whiteThreshold && b > whiteThreshold) ||
            pixels[i + 3] === 0
          ) {
            continue;
          }

          // Skip black pixels
          if (r < blackThreshold && g < blackThreshold && b < blackThreshold) {
            continue;
          }

          const xPos = (x * tileSize - w / 2) * 0.1;
          const yPos = (w / 2 - y * tileSize) * 0.1;

          sumX += xPos;
          sumY += yPos;
          count++;

          const zRand = Math.random() * 1 - 1; // random value between -1 and 1

          positions.push(xPos, yPos, zRand);

          const u = x / tiles;
          const v = y / tiles;
          uvs.push(u, v);
        }
      }

      const avgX = sumX / count;
      const avgY = sumY / count;
      setGeometryData({
        positions,
        uvs,
      });
      setTextureData({
        pixels,
        width: w,
        height: h,
      });

      setIsReady(true);
    });
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <Container config={config} id={id} className={className}>
      <Canvas
        gl={{ antialias: true }}
        style={{
          width: "100%",
          height: "100%",
        }}
        camera={{
          position: [0, 0, 15],
        }}
      >
        {isReady && (
          <Points geometryData={geometryData} textureData={textureData} />
        )}
        {/*
<axesHelper args={[10]} />
        <OrbitControls />

*/}
      </Canvas>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  > * {
    pointer-events: none !important;
  }
  ${(props) => ({ ...props.config })};
`;

export default MotionGraphic;
