import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

export default function HeadComponent({ page, global }) {
  const router = useRouter();
  const {
    meta_title,
    meta_description,
    og_image_meta,
    og_image_twitter,
    domain,
  } = page ?? {};
  const {
    default_title,
    default_description,
    default_og_image_meta,
    default_og_image_twitter,
    default_domain,
  } = global ?? {};

  const title = meta_title || default_title;
  const description = meta_description || default_description;
  const ogImageMeta = og_image_meta?.url || default_og_image_meta?.url;
  const ogImageTwitter = og_image_twitter?.url || default_og_image_twitter?.url;
  const domainUrl = domain || default_domain;
  const safeDomainUrl = meta_title
    ? default_domain + router.asPath
    : default_domain;

  return (
    <Head>
      <meta property="og:locale" content="sv_SE" />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {/* OG */}
      {ogImageMeta && <meta property="og:image" content={ogImageMeta} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {title && <meta property="og:image:alt" content={title} />}
      <meta property="og:type" content="website" />
      {safeDomainUrl && <meta property="og:url" content={safeDomainUrl} />}
      {domainUrl && <meta property="og:site_name" content={domainUrl} />}

      {/* TWITTER */}
      {ogImageTwitter && <meta name="twitter:image" content={ogImageTwitter} />}
      {title && <meta name="twitter:title" content={title} />}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter_card"
      />
      {description && <meta name="twitter:description" content={description} />}
      {title && <meta name="twitter:image:alt" content={title} />}

      <meta name="twitter:site" content="@ICAGruppen" />
      <meta name="twitter:creator" content="@ICAGruppen" />
    </Head>
  );
}
