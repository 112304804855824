import { useState, useEffect, useRef } from "react";

export default function useDirectionScroll({ threshold }) {
  const lastScrollTop = useRef(0);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY > threshold &&
        window.scrollY > lastScrollTop.current
      ) {
        setToggle(false);
      } else if (
        window.scrollY > threshold &&
        window.scrollY < lastScrollTop.current
      ) {
        setToggle(true);
      } else if (window.scrollY < threshold) {
        setToggle(true);
      }

      lastScrollTop.current = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { toggle };
}
