import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { Typography, useResponsive } from "@oskarengstrom/sage-ui";
import IconButton from "./IconButton";
import { ThemeContext, useTheme } from "@emotion/react";

const transition = "all 0.05s linear";

export default function ArticleNavButton({
  icon = "back",
  label = "Label",
  ...restProps
}) {
  const theme = useTheme(ThemeContext);
  const [hover, setHover] = useState(false);
  const { breakpoint, breakpointIndex } = useResponsive();
  // const { setCustomPointer } = useContext(PointerContext);

  // useEffect(() => {
  //   hover ? setCustomPointer(true) : setCustomPointer(false);
  // }, [hover]);

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // className="article-nav-button"
      {...restProps}
    >
      <IconButton
        icon={icon}
        color={hover ? theme.constants.white : theme.palette.primary.lighter}
        size={["36px", "36px", "48px"]}
        iconSize={breakpointIndex < 2 ? 20 : 24}
        css={{ transition }}
      />
      <Typography
        fontSize="15px"
        lineHeight={["18px", "18px", "20px"]}
        color="white"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); */
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 2px;
  padding-right: calc(1rem - 2px);
  border-radius: 100px;
  box-shadow: ${({ theme }) => theme.constants.shadows.button};
`;
