import * as React from "react";

const IcaLogo = ({ size = "medium", color = "#E3000B", ...restProps }) => {
  let width;
  switch (size) {
    case "small":
      width = 56;
      break;
    case "medium":
      width = 80;
      break;
  }

  return (
    <svg
      width={width}
      viewBox="0 0 80 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.63 0 80 39.26H68.667l-2.46-4.267H54.933l3.941-6.919h3.393l-4.652-8.207-4.874 8.518a34.495 34.495 0 0 1-2.297 3.511C46.726 36.8 40.696 39.941 34 39.941c-11.23 0-20.444-8.89-20.444-19.852C13.556 9.126 22.696.237 34 .237c3.837 0 7.289 1.013 10.296 2.711l-4.815 8.474A10.874 10.874 0 0 0 34 9.926c-5.748 0-10.4 4.533-10.4 10.118 0 5.6 4.652 10.119 10.4 10.119 3.126 0 5.689-1.279 7.556-3.14.822-.8 1.748-2.134 2.03-2.608L57.6-.178l.03.178ZM0 39.26V1.036h9.97V39.26H0Z"
        fill={color}
      />
    </svg>
  );
};

export default IcaLogo;
