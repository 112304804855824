export default function getSizeFromPath(pathData) {
  const replacedPathData = pathData.replace(/ /g, ",");
  const coordinates = replacedPathData.match(/-?[\d.]+,-?[\d.]+/g);
  let maxX = 0;
  let maxY = 0;

  coordinates.forEach((coordinate) => {
    const [x, y] = coordinate.split(",").map(parseFloat);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });

  return {
    width: Math.ceil(maxX),
    height: Math.ceil(maxY),
  };
}
