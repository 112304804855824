import { useContext } from "react";
import IcaLogo from "../ui/IcaLogo";
import {
  Box,
  Grid,
  Stack,
  Typography,
  useResponsive,
} from "@oskarengstrom/sage-ui";
import MenuButton from "../ui/MenuButton";
import { NavContext } from "./Nav";
import ArticleNavButton from "../ui/ArticleNavButton";
import MenuContactButton from "../ui/MenuContactButton";
import { useSetRecoilState } from "recoil";
import { pointerState } from "@/contexts/pointer/pointerState";
import { useTransition } from "@/hooks/Transition";
import { linkResolver } from "@/prismic-configuration";
import useDirectionScroll from "@/hooks/useDirectionScroll";

export default function NavBar() {
  const { open, setOpen, backButton, setActiveCategory } =
    useContext(NavContext);
  const { breakpointIndex } = useResponsive();
  const setPointer = useSetRecoilState(pointerState);
  const { TransitionLink } = useTransition();
  // const { toggle } = useDirectionScroll({ threshold: 400 });

  const textLinkStyles = (theme) => ({
    padding: "0.5rem 1rem",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
  });

  return (
    <Grid
      id="nav-bar"
      gridTemplateColumns={"1fr min-content 1fr"}
      gridTemplateAreas={"'left middle right'"}
      mt={[1.25, 1.25, 2.5]}
      px={[1.25, 3, 3.75]}
      as="nav"
      width="100vw" // Fixes jumping when menu open, bad alignment?
      css={{
        position: "fixed",
        top: 0,
        zIndex: 1000,
        alignItems: "center",
      }}
    >
      <TransitionLink
        href={"/"}
        onMouseEnter={() => setPointer({ type: "default" })}
        onMouseLeave={() => setPointer(false)}
        css={{ height: "fit-content", width: "fit-content" }}
      >
        <IcaLogo
          size={breakpointIndex < 2 ? "small" : "medium"}
          css={{ paddingTop: "4px" }}
        />
      </TransitionLink>

      {breakpointIndex !== false &&
        !(breakpointIndex > 1 && backButton.hideOnDesktop) &&
        backButton?.link && (
          <Box className="article-nav-button" gridArea="middle">
            <TransitionLink
              href={linkResolver(backButton.link)}
              onMouseEnter={() => setPointer({ type: "default" })}
              onMouseLeave={() => setPointer(false)}
              color={backButton.color}
            >
              <ArticleNavButton label={backButton.label} />
            </TransitionLink>
          </Box>
        )}

      <Stack
        gap={1}
        flexDirection="row"
        alignItems="center"
        css={{ justifySelf: "end", gridArea: "right" }}
      >
        <Stack gap={0.5} flexDirection="row">
          <MenuButton
            open={open}
            onClick={() => setOpen((s) => !s)}
            // onTouchStart={() => setOpen((s) => !s)}
            onMouseEnter={() => setPointer({ type: "default" })}
            onMouseLeave={() => setPointer(false)}
          />
        </Stack>
      </Stack>
    </Grid>
  );
}
