import React, { useContext } from "react";
import { Stack, Typography as T, Box } from "@oskarengstrom/sage-ui";
import styled from "@emotion/styled";
import { linkResolver } from "prismic-configuration";
import { NavContext } from "./Nav";
import { useTransition } from "@/hooks/Transition";
import slugifyString from "@/utils/slugifyString";
import { useSetRecoilState } from "recoil";
import { pointerState } from "@/contexts/pointer/pointerState";
import MenuItemGroup from "./MenuItemGroup";
import { PrismicLink } from "@prismicio/react";

export default function OpenMenu() {
  const { navData } = useContext(NavContext);
  const { TransitionLink } = useTransition();
  const setPointer = useSetRecoilState(pointerState);

  if (!navData) return null;
  const { general_pages, slices, welcome_label, welcome_link } = navData.data;

  return (
    <>
      <Box
        id="menu-bg"
        position="fixed"
        inset="0"
        backgroundColor="white"
        zIndex="800"
      />
      <Menu id="menu">
        <Stack
          gap={2}
          py={[4, null, 5.75]}
          pb={[10, 0]}
          minHeight="100%"
          justifyContent="center"
          // css={{ flexGrow: 1 }}
        >
          <Stack
            className="article-nav-item-welcome"
            flexDirection="row"
            justifyContent="center"
            // display={["none", null, "flex"]}
            css={(theme) => ({
              flexShrink: 0,
              "&:hover .article-nav-item": {
                color: theme.constants.colors.alpha.grey,
              },
              "&:focus": {
                outline: "none",
                "& .article-nav-item": {
                  color: theme.constants.colors.alpha.grey,
                },
              },
            })}
          >
            <TransitionLink
              href={linkResolver(welcome_link)}
              onMouseEnter={() => setPointer({ type: "default" })}
              onMouseLeave={() => setPointer(false)}
              // color="constants.colors.pink.primary"
            >
              <T variant="labelLarge" className="article-nav-item">
                {welcome_label}
              </T>
            </TransitionLink>
          </Stack>
          <Stack gap={0.5}>
            {slices.map((item) => (
              <MenuItemGroup
                key={slugifyString(item.primary.primary_label)}
                item={item}
              />
            ))}
          </Stack>
          <Stack
            className="article-nav-item-wrapper"
            flexDirection={["column", null, "row"]}
            justifyContent="center"
            alignItems="center"
            gap={1.5}
          >
            {general_pages.map(({ label, link }) => {
              if (link.link_type === "Media") {
                return (
                  <Box key={label}>
                    <PrismicLink
                      field={link}
                      onMouseEnter={() => setPointer({ type: "default" })}
                      onMouseLeave={() => setPointer(false)}
                      css={(theme) => ({
                        flexShrink: 0,
                        "&:hover .article-nav-item": {
                          color: theme.constants.colors.alpha.grey,
                        },
                        "&:focus": {
                          outline: "none",
                          "& .article-nav-item": {
                            color: theme.constants.colors.alpha.grey,
                          },
                        },
                      })}
                    >
                      <T
                        key={label}
                        variant="labelLarge"
                        className="article-nav-item"
                      >
                        {label}
                      </T>
                    </PrismicLink>
                  </Box>
                );
              }

              return (
                <Box key={label}>
                  <TransitionLink
                    href={linkResolver(link)}
                    onMouseEnter={() => setPointer({ type: "default" })}
                    onMouseLeave={() => setPointer(false)}
                    // color="constants.colors.pink.primary"
                    css={(theme) => ({
                      flexShrink: 0,
                      "&:hover .article-nav-item": {
                        color: theme.constants.colors.alpha.grey,
                      },
                      "&:focus": {
                        outline: "none",
                        "& .article-nav-item": {
                          color: theme.constants.colors.alpha.grey,
                        },
                      },
                    })}
                  >
                    <T
                      key={label}
                      variant="labelLarge"
                      className="article-nav-item"
                      as="span"
                    >
                      {label}
                    </T>
                  </TransitionLink>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}

const Menu = styled(Box)`
  visibility: hidden;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  /* background: white; */
`;
