import { mq } from "@oskarengstrom/sage-ui";

function generateStyles(scope) {
  const { containerWidth, containerHeight, theme } = scope;

  return {
    "#canvas-video1": {
      "--size": "15rem",
      position: "absolute",
      transform: "translate(34rem, 33rem)",
      top: "50%",
      left: "50%",
      width: "var(--size)",
      height: "var(--size)",
      [mq.only.touchDevice]: {
        position: "relative",
        top: "auto",
        left: "auto",
        transform: "none",
      },
    },
  };
}

export default {
  generateStyles,
};
