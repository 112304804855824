import styled from "@emotion/styled";
import { mq } from "@oskarengstrom/sage-ui";
import { PrismicNextImage } from "@prismicio/next";
import { useTheme, ThemeContext } from "@emotion/react";
import MotionGraphic from "@/components/MotionGraphic";

const RenderLayer = ({
  layer,
  tree,
  section_id,
  config,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  scope,
}) => {
  const theme = useTheme();

  return (
    <Layer id={`${section_id}_${layer}`}>
      {tree[layer]?.clipped?.map((item) => (
        <Clipped
          key={item.name}
          clipId={`${section_id}_${item.name}`}
          id={`${section_id}_clipped_${item.name}`}
          className={`${item.name}`}
          config={config[item.name]}
          hoverBlob={item.name === "main-blob"}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={(e) => {
            e.stopPropagation();
          }}
          onMouseOut={(e) => {
            e.stopPropagation();
          }}
          onClick={onClick}
          {...scope}
        >
          {item.clipped?.map((nestedItem) => (
            <Clipped
              key={nestedItem.name}
              clipId={`${section_id}_${nestedItem.name}`}
              id={`${section_id}_clipped_${nestedItem.name}`}
              className={`${nestedItem.name}`}
              config={config[nestedItem.name]}
              hoverBlob={nestedItem.name === "main-blob"}
              {...scope}
            >
              {nestedItem.images?.map((item) => (
                <Image
                  key={item.name}
                  field={item.image}
                  id={`${section_id}_image_${item.name}`}
                  className={`${item.name}`}
                  fallbackAlt=""
                  config={config[item.name]}
                  priority={true}
                  sizes={`
                    ${config[item.name]?.width}
                  `}
                />
              ))}
              {nestedItem.graphics?.map((item) => (
                <MotionGraphic
                  key={item.name}
                  id={`${section_id}_graphic_${item.name}`}
                  className={`${item.name}`}
                  config={config[item.name]}
                  field={item.image}
                  {...scope}
                />
              ))}
            </Clipped>
          ))}
          {item.images?.map((item) => (
            <Image
              key={item.name}
              field={item.image}
              id={`${section_id}_image_${item.name}`}
              className={`${item.name} image`}
              fallbackAlt=""
              config={config[item.name]}
              priority={true}
              sizes={`
                ${config[item.name]?.width} 
                  `}
            />
          ))}
          {item.graphics?.map((item) => (
            <MotionGraphic
              key={item.name}
              id={`${section_id}_graphic_${item.name}`}
              className={`${item.name}`}
              config={config[item.name]}
              field={item.image}
              {...scope}
            />
          ))}
        </Clipped>
      ))}
      {tree[layer]?.unclipped?.images?.map((item) => (
        <Image
          key={item.name}
          id={`${section_id}_image_${item.name}`}
          className={`${item.name}`}
          field={item.image}
          fallbackAlt=""
          config={config[item.name]}
          priority={true}
          sizes={`
            ${config[item.name]?.width} 
                  `}
        />
      ))}
      {tree[layer]?.unclipped?.graphics?.map((item) => (
        <MotionGraphic
          key={item.name}
          id={`${section_id}_graphic_${item.name}`}
          className={`${item.name}`}
          config={config[item.name]}
          field={item.image}
          {...scope}
        />
      ))}
      {children}
    </Layer>
  );
};

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Image = styled(PrismicNextImage)`
  position: absolute;
  height: auto;
  ${(props) => ({ ...(props.config || {}) })};
  will-change: transform;
  pointer-events: none;
  ${mq.only.touchDevice} {
    will-change: unset;
  }
`;

const Clipped = styled.div`
  ${(props) => ({ ...(props.config || {}) })};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  transform-origin: unset;
  will-change: transform;
  clip-path: url(#${(props) => props.clipId});

  pointer-events: ${(props) => (props.hoverBlob ? "auto" : "none")};
  ${mq.only.touchDevice} {
    will-change: unset;
  }
`;

export default RenderLayer;
