import React, { useContext, useState } from "react";
import { Box, Typography, useResponsive } from "@oskarengstrom/sage-ui";
import styled from "@emotion/styled";
import BackIcon from "../icons/BackIcon";
import { baseTheme as theme } from "@/styles/theme";

export default function ToggleButton({
  label = "Label",
  color = "lightpink",
  toggle = false,
  uid,
  hover,
  ...restProps
}) {
  const { breakpointIndex } = useResponsive();
  const isM = breakpointIndex < 2;
  return (
    <div>
      <Wrapper
        className="animationScope"
        color={hover ? color : theme.constants.colors.alpha.lightgrey}
        height={["4.5rem", null, "6.25rem"]}
        {...restProps}
      >
        <Typography
          variant="heading3"
          whiteSpace="nowrap"
          userSelect="none"
          css={{
            padding:
              toggle || hover
                ? isM
                  ? "0 1.5rem 0 2rem"
                  : "0 1.5rem 0 3rem"
                : isM
                ? "0 2rem 0 2rem"
                : "0 3rem 0 3rem",
            transition: "all 0.25s ease-in-out",
          }}
        >
          {label}
        </Typography>

        <div
          css={{
            maxWidth: hover || toggle ? "5.75rem" : "0",
            opacity: hover || toggle ? 1 : 0,
            transition: "all 0.25s ease-in-out",
          }}
        >
          <Icon
            color={"white"}
            height={["4rem", null, "5.75rem"]}
            width={["4rem", null, "5.75rem"]}
          >
            <BackIcon
              size={breakpointIndex > 1 ? 32 : 24}
              css={{
                transform: toggle
                  ? "rotate(90deg)"
                  : hover
                  ? "rotate(-90deg)"
                  : "rotate(0deg)",
                transition: "all 0.25s ease-in-out",
              }}
            />
          </Icon>
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled(Box)(({ color }) => ({
  // ...buttonResetStyles,
  display: "grid",
  width: "min-content",
  // height: "6.25rem",
  gridTemplateColumns: "max-content max-content",
  alignItems: "center",
  backgroundColor: color,
  padding: "0.25rem",
  borderRadius: "10rem",
  outline: "none",
  transition: "all 0.25s ease-in-out",
  overflow: "hidden",
}));

const Icon = styled(Box)(({ theme, color }) => ({
  // height: "5.75rem",
  // width: "5.75rem",
  borderRadius: "50%",
  backgroundColor: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));
