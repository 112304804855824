import * as React from "react";

const PaperPlaneIcon = ({ size = 24, ...restProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M19.716 3.366 2.24 8.288a.75.75 0 0 0-.113 1.406l8.025 3.797c.157.072.284.199.356.356l3.797 8.025a.75.75 0 0 0 1.407-.113l4.921-17.475a.74.74 0 0 0-.918-.918ZM10.396 13.603l4.238-4.238"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default PaperPlaneIcon;
