import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Stack, Typography } from "@oskarengstrom/sage-ui";
import Link from "next/link";
import { useSetRecoilState } from "recoil";
import { pointerState } from "@/contexts/pointer/pointerState";

const RichText = React.forwardRef(({ field, ...rest }, ref) => {
  const setPointer = useSetRecoilState(pointerState);
  if (typeof field === "string") {
    console.warn(
      `RichText: field "${field}" is a string, not a Prismic RichText object.`
    );
    return <Typography {...rest}>{field}</Typography>;
  }

  return (
    <PrismicRichText
      ref={ref}
      field={field}
      internalLinkComponent={Link}
      externalLinkComponent={(props) => (
        <a target="_blank" rel="noopener noreferrer" {...props} />
      )}
      components={{
        heading1: ({ children }) => (
          <Typography variant="heading1" as="h1" {...rest}>
            {children}
          </Typography>
        ),
        heading2: ({ children }) => (
          <Typography variant="heading2" as="h2" {...rest}>
            {children}
          </Typography>
        ),
        heading3: ({ children }) => (
          <Typography variant="heading3" as="h3" {...rest}>
            {children}
          </Typography>
        ),
        heading4: ({ children }) => (
          <Typography variant="heading4" as="h4" {...rest}>
            {children}
          </Typography>
        ),
        paragraph: ({ children }) => (
          <Typography variant="paragraphLarge" as="p" {...rest}>
            {children}
          </Typography>
        ),
        paragraphMedium: ({ children }) => (
          <Typography variant="paragraphMedium" as="p" {...rest}>
            {children}
          </Typography>
        ),
        labelLarge: ({ children }) => (
          <Typography variant="labelLarge" as="span" {...rest}>
            {children}
          </Typography>
        ),
        labelSmall: ({ children }) => (
          <Typography variant="labelSmall" as="span" {...rest}>
            {children}
          </Typography>
        ),
        framtid: ({ children }) => (
          <Typography variant="framtid" as="span" {...rest}>
            {children}
          </Typography>
        ),
        list: ({ children }) => (
          <Stack
            as="ul"
            gap={0.75}
            pl={1.25}
            // css={{ listStylePosition: "inside" }}
          >
            {children}
          </Stack>
        ),
        oList: ({ children }) => (
          <Stack
            as="ol"
            gap={0.75}
            pl={1.25}
            // css={{ listStylePosition: "inside" }}
          >
            {children}
          </Stack>
        ),
        listItem: ({ children }) => (
          <Typography variant="paragraphLarge" as="li" {...rest}>
            {children}
          </Typography>
        ),
        oListItem: ({ children }) => (
          <Typography variant="paragraphLarge" as="li" {...rest}>
            {children}
          </Typography>
        ),
        hyperlink: (props) => (
          <a
            href={props.node.data.url}
            className="inline-link"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setPointer({ type: "default" })}
            onMouseLeave={() => setPointer(false)}
            css={{
              [`&:hover`]: {
                opacity: 0.5,
              },
            }}
          >
            {props.children}
          </a>
        ),
      }}
    />
  );
});

export default RichText;
