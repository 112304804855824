export default function renderLayoutTree(images_, blobs_, graphics_, device) {
  let blobs = blobs_;
  let graphics = graphics_;
  let images = images_;

  if (device) {
    blobs = blobs_.filter(
      (b) => b.device === device || b.device === "all" || !b.device
    );
    graphics = graphics_.filter(
      (g) => g.device === device || g.device === "all" || !g.device
    );
    images = images_.filter(
      (i) => i.device === device || i.device === "all" || !i.device
    );
  }

  function makeLayer(images, blobs, graphics, layer) {
    return {
      clipped: blobs
        .filter((b) => b.layer === layer && !b.clipped_to)
        .map((blob) => ({
          name: blob.name,
          images: images.filter(
            (im) => im.layer === layer && im.clipped_to === blob.name
          ),
          graphics: graphics.filter(
            (im) => im.layer === layer && im.clipped_to === blob.name
          ),
          clipped: blobs
            .filter((b) => b.clipped_to === blob.name)
            .map((blob) => ({
              name: blob.name,
              images: images.filter((im) => im.clipped_to === blob.name),
              graphics: graphics.filter((im) => im.clipped_to === blob.name),
            })),
        })),
      unclipped: {
        images: images.filter((im) => im.layer === layer && !im.clipped_to),
        graphics: graphics.filter((im) => im.layer === layer && !im.clipped_to),
      },
    };
  }

  return {
    back: makeLayer(images, blobs, graphics, "back"),
    middle: makeLayer(images, blobs, graphics, "middle"),
    front: makeLayer(images, blobs, graphics, "front"),
  };
}
