import React, { useEffect } from "react";
import Head from "next/head";
import Nav from "./Nav/Nav";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import HeadComponent from "./HeadComponent";
gsap.registerPlugin(ScrollTrigger);

const Layout = React.forwardRef(
  (
    {
      header,
      footer,
      global,
      page,
      navData,
      backButton, // = { label: "Tillbaka", link: link },
      children,
      ...restProps
    },
    ref
  ) => {
    // refresh scrolltrigger on page load  and when images are lazy loaded
    useEffect(() => {
      if (typeof window !== "undefined") {
        window.onload = () => {
          ScrollTrigger.refresh();
        };
      }

      // if (ScrollTrigger.isTouch === 1) {
      //   ScrollTrigger.config({
      //     autoRefreshEvents: "DOMContentLoaded,load",
      //   });
      // }

      // document.querySelectorAll("img").forEach((img) => {
      //   if (img.complete) {
      //     ScrollTrigger.refresh();
      //   } else {
      //     img.addEventListener("load", (imageLoaded) => {
      //       ScrollTrigger.refresh();
      //     });
      //   }
      // });
    }, []);

    return (
      <>
        <HeadComponent page={page} global={global} />
        <Nav backButton={backButton} navData={navData} />
        <main id="main" ref={ref} {...restProps}>
          {children}
        </main>
      </>
    );
  }
);

Layout.displayName = "Layout";

export default Layout;
