import { Stack } from "@oskarengstrom/sage-ui";
import React from "react";
import CloseIcon from "../icons/CloseIcon";
import { ThemeContext, useTheme } from "@emotion/react";
import { useHover } from "@/utils/useHover";

export default function PortalCloseButton({ ...props }) {
  const theme = useTheme(ThemeContext);
  const [hoverRef, isHovered] = useHover();

  return (
    <Stack
      ref={hoverRef}
      position="absolute"
      top="2rem"
      right="2rem"
      zIndex={2}
      height="40px"
      width="40px"
      backgroundColor={isHovered ? theme.palette.primary.light : "white"}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
      css={{ cursor: "pointer" }}
      {...props}
    >
      <CloseIcon color="black" />
    </Stack>
  );
}
