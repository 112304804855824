import * as React from "react";

const DownArrowIcon = ({ size = 24, ...restProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M12 3.75v16.5M18.75 13.5 12 20.25 5.25 13.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default DownArrowIcon;
